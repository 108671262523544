var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.transaction)?_c('div',{staticClass:"fixBox"},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"alt":"","width":"200"}}),_c('h1',[_vm._v("Redirecting to website")]),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#274E87"}})],1):_vm._e(),_c('v-container',{staticClass:"container-custom mb-10"},[_c('h1',[_vm._v("Checkout")]),_c('v-row',[(_vm.loading)?_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"shadowBox py-5"},[_c('Loading')],1)]):_vm._e(),(!_vm.loading)?_c('v-col',{attrs:{"md":"7"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Items")]),_c('div',{staticClass:"shadowBox"},[_vm._l((_vm.allCheckouts.results),function(data,index){return _c('div',{key:index + 'cart',staticClass:"item"},[_c('CartItem',{attrs:{"item":data,"checkout":true}})],1)}),(_vm.allCheckouts.results.length == 0)?_c('div',{staticClass:"noCart"},[_c('v-icon',[_vm._v(" mdi-timer-sand-empty ")]),_vm._v(" No product added to your cart ")],1):_vm._e()],2),_c('div',{staticClass:"totalCalculation pb-0"},[_c('h3',[_vm._v("Payments")]),_c('ul',{staticClass:"paymentBox"},[_c('li',{on:{"click":function($event){return _vm.paymentChooseAct('cod')}}},[_c('span',[_vm._v("COD")]),_c('strong',[(_vm.paymentSelected == 'cod')?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()],1)]),_c('li',{on:{"click":function($event){return _vm.paymentChooseAct('connectips')}}},[_c('span',[_c('img',{attrs:{"src":require("@/assets/images/connectIPS.png"),"width":"50"}})]),_c('strong',[(_vm.paymentSelected == 'connectips')?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()],1)])])])]):_vm._e(),(!_vm.loading)?_c('v-col',{attrs:{"md":"5"}},[_c('div',[_c('h3',[_vm._v("Address")]),(!_vm.loadingAddress)?_c('AddressBox'):_vm._e(),(
              _vm.allAddresses &&
              _vm.allAddresses.results.length == 0 &&
              !_vm.loadingAddress
            )?_c('router-link',{staticClass:"addAddressBtn",attrs:{"to":"/addAddress"}},[_vm._v("Add Address")]):_vm._e(),(
              _vm.allAddresses &&
              _vm.allAddresses.results.length != 2 &&
              !_vm.loadingAddress
            )?_c('v-checkbox',{staticClass:"mt-0 mb-4 mb-5 text-capitalize",attrs:{"label":`Make ${
              this.allAddresses.results[0].addressType == 'billingAddress'
                ? 'Shipping Address'
                : 'Billing Address'
            } Same as ${
              this.allAddresses.results[0].addressType == 'billingAddress'
                ? 'Billing Address'
                : 'Shipping Address'
            }`},on:{"click":_vm.addAddress}}):_vm._e(),(
              _vm.allAddresses &&
              _vm.allAddresses.results.length != 2 &&
              _vm.loadingAddress
            )?_c('v-btn',{staticClass:"my-4 mb-5",attrs:{"loading":""}}):_vm._e()],1),_c('div',{staticClass:"totalCalculation"},[_c('h3',[_vm._v("Order Summary")]),_c('ul',[_c('li',[_c('span',[_vm._v("Subtotal")]),_c('strong',[_vm._v("Rs. "+_vm._s(_vm.numberFormat(_vm.allCheckouts.details.subTotal)))])]),_c('li',[_c('span',[_vm._v("Discount")]),_c('strong',[_vm._v("Rs. "+_vm._s(_vm.numberFormat(_vm.allCheckouts.details.totalDiscount)))])]),_c('li',[_c('span',[_vm._v("Shipping")]),_c('strong',[_vm._v("Rs. "+_vm._s(_vm.numberFormat(_vm.allCheckouts.details.shipping)))])]),_c('li',[_c('span',[_vm._v("Total")]),_c('strong',[_vm._v("Rs. "+_vm._s(_vm.numberFormat(_vm.allCheckouts.details.total)))])])]),_c('div',{staticClass:"defaultBtn"},[(!_vm.loadingOrder && _vm.paymentSelected == 'cod')?_c('v-btn',{attrs:{"disabled":_vm.allAddresses && _vm.allAddresses.results.length != 2},on:{"click":_vm.createOrder}},[_vm._v("Confirm")]):_vm._e(),(!_vm.loadingOrder && _vm.paymentSelected == 'connectips')?_c('v-btn',{attrs:{"disabled":_vm.allAddresses && _vm.allAddresses.results.length != 2},on:{"click":_vm.connectIPSAct}},[_vm._v("Pay with "+_vm._s(_vm.paymentSelected))]):_vm._e(),(_vm.loadingOrder)?_c('v-btn',{attrs:{"loading":""}}):_vm._e(),(_vm.allAddresses && _vm.allAddresses.results.length < 2)?_c('center',{staticClass:"pt-5"},[_vm._v(" Please add "+_vm._s(this.allAddresses.results[0].addressType == "shippingAddress" ? "Billing Address" : "Shipping Address")+" ")]):_vm._e()],1)])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }