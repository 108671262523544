<template>
  <section>
    <div v-if="transaction" class="fixBox">
      <img src="@/assets/logo.png" alt="" width="200" />
      <h1>Redirecting to website</h1>
      <v-progress-circular indeterminate color="#274E87"></v-progress-circular>
    </div>
    <v-container class="container-custom mb-10">
      <h1>Checkout</h1>
      <v-row>
        <v-col md="12" v-if="loading">
          <div class="shadowBox py-5">
            <Loading />
          </div>
        </v-col>
        <v-col md="7" v-if="!loading">
          <h3 class="mb-3">Items</h3>
          <div class="shadowBox">
            <div
              class="item"
              v-for="(data, index) in allCheckouts.results"
              :key="index + 'cart'"
            >
              <CartItem :item="data" :checkout="true" />
            </div>
            <div v-if="allCheckouts.results.length == 0" class="noCart">
              <v-icon> mdi-timer-sand-empty </v-icon>
              No product added to your cart
            </div>
          </div>
          <div class="totalCalculation pb-0">
            <h3>Payments</h3>
            <ul class="paymentBox">
              <li @click="paymentChooseAct('cod')">
                <span>COD</span>
                <strong
                  ><v-icon v-if="paymentSelected == 'cod'">
                    mdi-check
                  </v-icon></strong
                >
              </li>
              <li @click="paymentChooseAct('connectips')">
                <span
                  ><img src="@/assets/images/connectIPS.png" width="50"
                /></span>
                <strong
                  ><v-icon v-if="paymentSelected == 'connectips'">
                    mdi-check
                  </v-icon>
                </strong>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col md="5" v-if="!loading">
          <div>
            <h3>Address</h3>
            <AddressBox v-if="!loadingAddress" />
            <router-link
              to="/addAddress"
              class="addAddressBtn"
              v-if="
                allAddresses &&
                allAddresses.results.length == 0 &&
                !loadingAddress
              "
              >Add Address</router-link
            >
            <v-checkbox
              class="mt-0 mb-4 mb-5 text-capitalize"
              v-if="
                allAddresses &&
                allAddresses.results.length != 2 &&
                !loadingAddress
              "
              @click="addAddress"
              :label="`Make ${
                this.allAddresses.results[0].addressType == 'billingAddress'
                  ? 'Shipping Address'
                  : 'Billing Address'
              } Same as ${
                this.allAddresses.results[0].addressType == 'billingAddress'
                  ? 'Billing Address'
                  : 'Shipping Address'
              }`"
            >
            </v-checkbox>
            <v-btn
              class="my-4 mb-5"
              v-if="
                allAddresses &&
                allAddresses.results.length != 2 &&
                loadingAddress
              "
              loading
            >
            </v-btn>
          </div>
          <div class="totalCalculation">
            <h3>Order Summary</h3>
            <ul>
              <li>
                <span>Subtotal</span>
                <strong
                  >Rs. {{ numberFormat(allCheckouts.details.subTotal) }}</strong
                >
              </li>
              <li>
                <span>Discount</span>
                <strong
                  >Rs.
                  {{ numberFormat(allCheckouts.details.totalDiscount) }}</strong
                >
              </li>
              <li>
                <span>Shipping</span>
                <strong
                  >Rs. {{ numberFormat(allCheckouts.details.shipping) }}</strong
                >
              </li>
              <li>
                <span>Total</span>
                <strong
                  >Rs. {{ numberFormat(allCheckouts.details.total) }}</strong
                >
              </li>
            </ul>
            <div class="defaultBtn">
              <v-btn
                v-if="!loadingOrder && paymentSelected == 'cod'"
                @click="createOrder"
                :disabled="allAddresses && allAddresses.results.length != 2"
                >Confirm</v-btn
              >
              <v-btn
                v-if="!loadingOrder && paymentSelected == 'connectips'"
                @click="connectIPSAct"
                :disabled="allAddresses && allAddresses.results.length != 2"
                >Pay with {{ paymentSelected }}</v-btn
              >
              <v-btn v-if="loadingOrder" loading></v-btn>

              <center
                v-if="allAddresses && allAddresses.results.length < 2"
                class="pt-5"
              >
                Please add
                {{
                  this.allAddresses.results[0].addressType == "shippingAddress"
                    ? "Billing Address"
                    : "Shipping Address"
                }}
              </center>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import CartItem from "@/components/CartItem";
import AddressBox from "@/components/AddressBox";
import Loading from "@/components/Loading";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Cart",
  data: () => ({
    loading: true,
    loadingOrder: false,
    paymentSelected: "cod",
    connectIPS: false,
    paymentStatus: false,
    transaction: false,
    loadingAddress: false,
  }),
  components: {
    CartItem,
    AddressBox,
    Loading,
  },
  methods: {
    ...mapActions([
      "getCheckouts",
      "postOrder",
      "getConnectIPS",
      "postAddress",
      "getAddresses",
    ]),
    async createOrder() {
      this.loadingOrder = true;
      await this.postOrder({
        paymentType: this.paymentSelected,
        paymentStatus: this.paymentStatus,
      });
      this.$router.push(`/thankyou/${this.allOrder.results.orderCode}`);
      this.loadingOrder = false;
    },
    async paymentChooseAct(data) {
      this.paymentSelected = data;
    },
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-IN");
      return internationalNumberFormat.format(givenNumber);
    },
    connectIPSAct() {
      let params = {
        APPID: this.allConnectIPS.results.data.app_id,
        APPNAME: this.allConnectIPS.results.data.app_name,
        MERCHANTID: this.allConnectIPS.results.data.merchant_id,
        TXNID: this.allConnectIPS.results.data.txnId,
        TXNDATE: this.allConnectIPS.results.data.txnDate,
        TXNCRNCY: this.allConnectIPS.results.data.txn_currency,
        TXNAMT: this.allConnectIPS.results.data.txnAmt,
        REFERENCEID: this.allConnectIPS.results.data.refId,
        REMARKS: this.allConnectIPS.results.data.remark,
        PARTICULARS: this.allConnectIPS.results.data.particulars,
        TOKEN: this.allConnectIPS.results.connectIPSToken,
      };

      // let path = "https://uat.connectips.com:7443/connectipswebgw/loginpage";
      let path = "https://login.connectips.com/connectipswebgw/loginpage";

      var form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", path);

      for (var key in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    },

    async addAddress() {
      this.loadingAddress = true;
      let data = {};
      data.addressType =
        this.allAddresses.results[0].addressType == "billingAddress"
          ? "shippingAddress"
          : "billingAddress";
      data.fullname = this.allAddresses.results[0].fullname;
      data.email = this.allAddresses.results[0].email;
      data.phone1 = this.allAddresses.results[0].phone1;
      data.phone2 = this.allAddresses.results[0].phone2;
      data.province = this.allAddresses.results[0].province;
      data.address = this.allAddresses.results[0].address;
      data.nearestLandmark = this.allAddresses.results[0].nearestLandmark;
      data.valley = this.allAddresses.results[0].valley;
      data.ringRoad = this.allAddresses.results[0].ringRoad;
      data.default = true;

      await this.postAddress(data);

      await this.getAddresses({
        limit: this.limit,
        page: this.page,
        default: true,
      });
      console.log(this.allAddresses);
      this.loadingAddress = false;
    },
  },
  computed: {
    ...mapGetters([
      "allCheckouts",
      "allOrder",
      "allAddresses",
      "allConnectIPS",
    ]),
  },

  async created() {
    if (this.$route.params.response) {
      if (this.$route.params.response == "success") {
        this.transaction = true;
        this.paymentSelected = "connectips";
        this.paymentStatus = true;
        await this.createOrder();
      } else {
        this.$router.push(`/thankyou/noorderplaced`);
      }
    } else {
      this.getConnectIPS();
      await this.getCheckouts();
      this.loading = false;
    }
    if (this.allCheckouts.results.length == 0) {
      this.$router.push("/cart");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.noCart {
  display: flex;
  align-items: center;
  .v-icon {
    margin-right: 15px;
  }
}
h1 {
  font-size: 30px;
  padding: 20px 0;
}
.snakbar {
  z-index: 999;
}
ul {
  padding-left: 0;
}
.totalCalculation {
  width: 100%;
  font-size: 16px;

  h3 {
    margin-bottom: 20px;
    margin-top: 25px;
    color: #0878d4;
  }
  ul {
    display: flex;
    flex-flow: column;
    padding-left: 0;
    margin-bottom: 25px;
    li {
      margin: 0;
      display: flex;
      justify-content: space-between;
      border: 1px solid #efefefef;
      border-left: 0;
      border-right: 0;
      padding: 10px 0;
      span {
        color: #8c8c8c;
      }
      strong {
        font-weight: 500;
      }
      &:last-of-type {
        strong {
          font-weight: 700;
        }
      }
    }
  }
}
.companyDetail {
  padding: 0 20px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
}
.cartInfo {
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;

  border-bottom: 1px solid #efefef;
  .cartBtn {
    color: #2ec574;
    font-weight: 600;
    font-size: 14px;
  }
}
.item {
  margin-bottom: 10px;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  padding: 15px 0;

  &:last-of-type {
    border-bottom: none;
  }
}
.defaultBtn {
  margin-bottom: 80px;
  .v-btn {
    width: 100%;
  }
}
a {
  .v-btn {
    min-height: 60px;
    width: 150px;
  }
}
.totalCalculation {
  h3 {
    color: #000;
    &:first-of-type {
      margin-top: 0;
    }
  }
  padding: 20px 20px;
  box-shadow: 0 0 5px #d0cece;
  border-radius: 8px;
  .defaultBtn {
    margin-bottom: 0;
  }
}
.shadowBox {
  box-shadow: 0 0 5px #d0cece;
  padding: 0 15px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.v-btn {
  min-height: 50px;
  color: #fff;
}
.addAddressBtn {
  color: #0878d4;
  border: 1px solid #0878d4;
  padding: 10px 20px;
  display: block;
  width: 150px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}
.paymentBox {
  display: flex;
  align-items: center;
  width: 100%;
  li {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
}
.fixBox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
</style>
